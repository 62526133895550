<template>
    <div class="h5box">
      <!-- 首页 -->
    <div class="box">
      <H5Carousel/>
    </div>
    <div class="h5enter">
      <div class="h5left" @click.stop="Mask1()">
        <transition name="lyric">
        <div class="shows" v-show="aaa">
             <p>
               <span @click="gopage('展会排期','TradeShowTrends')">展馆排期</span>
              </p>
            <p>
               <span @click="gopage('展览场地','exhibition')">场馆技术参数</span>
              </p>
            <p>
              <span @click="gopage('场地动线图','datadownload')">入场动线</span>
              </p>
<!--            <p>
              <span @click="gopage('办展流程','TradeShowTrends')">办展流程</span>
              </p>-->
            <p>
              <span @click="gopage('管理规定','datadownload')">管理规定</span>
              </p>
            <p>
              <span @click="gopage('餐饮服务','ExhibitionServices')">餐饮服务</span>
              </p>
            <p>
              <span @click="gopage('搭建服务','ExhibitionServices')">搭建服务</span>
              </p>
            <p>
              <span @click="gopage('展具租赁','ExhibitionServices')">展具租赁</span>
              </p>
            <p>
             <span @click="gopage('广告服务','ExhibitionServices')">广告服务</span>
              </p>
            <p>
             <span @click="gopage('宣传手册','datadownload')">宣传手册</span>
              </p>
            <p>
             <span @click="gopage('交通指南','traffic')">交通指南</span>
              </p>
            <p>
            <span @click="gopage('周边酒店','traffic')">周边酒店</span>
              </p>
        </div>
        </transition>
        <p class="centers">
          {{ $t("language.hostentry") }}
          <!-- 主办入口 -->
        </p>
        <p class="centcon">
          {{ $t("language.inbookingthevenue") }}
          <!-- 作为主办方，我有意向预定场地 -->
        </p>
      </div>
      <div class="h5center" @click.stop="Mask2()">
        <transition name="lyric">
        <div class="shows" v-show="bbb">
            <p  style="margin-right: 0.12rem;">
             <span @click="gopage('展览场地','exhibition')">场馆技术参数</span>
              </p>
            <p>
             <span @click="gopage('交通指南','traffic')">交通指南</span>
              </p>
            <p>
             <span @click="gopage('场地动线图','datadownload')">货运动线</span>
              </p>
            <p>
             <span @click="gopage('展具租赁','ExhibitionServices')">展具租赁</span>
              </p>
            <p>
             <span @click="gopage('搭建服务','ExhibitionServices')">搭建服务</span>
              </p>
            <p>
             <span @click="gopage('管理规定','datadownload')">管理规定</span>
              </p>
            <p>
             <span @click="gopage('周边酒店','traffic')">周边酒店</span>
              </p>
            <p>
             <span @click="gopage('餐饮服务','ExhibitionServices')">餐饮服务</span>
              </p>
        </div>
        </transition>
        <p class="centers">
          {{ $t("language.ExhibitorEntrance") }}
          <!-- 展商入口 -->
        </p>
        <p class="centcon">
           {{ $t("language.ExhibitorEntranceText") }}
          <!-- 作为展商，我有意向参与布展 -->
        </p>
      </div>
      <div class="h5right" @click.stop="Mask3()">
        <transition name="lyric">
        <div class="shows" v-show="ccc">
            <p>
             <span @click="gopage('展览场地','exhibition')">展览场地</span>
              </p>
            <p>
             <span @click="gopage('交通指南','traffic')">交通指南</span>
              </p>
            <p>
             <span @click="gopage('周边酒店','traffic')">周边酒店</span>
              </p>
            <p>
             <span @click="gopage('旅游景点','traffic')">旅游景点</span>
              </p>
        </div>
        </transition>
        <p class="centers">
           {{ $t("language.AudienceEntrance") }}
          <!-- 观众入口 -->
        </p>
        <p class="centcon">
           {{ $t("language.AudienceEntranceText") }}
          <!-- 作为观众，我有意向参观展览 -->
        </p>
      </div>
    </div>
    <div class="journalism">
      <div class="H5nav">
        <span>
           {{ $t("language.NewInformation") }}
          <!-- 展馆新闻 -->
        </span> <span @click="goNews()">MORE</span>
      </div>
      <div class="h5explain">
        <div class="h5explainleft">
          <p>Hot</p>
          <p></p>
          <p>{{date.date}}</p>
        </div>
        <div class="h5explainright" @click="lookparticulars(date.id)">
              <span class="righttitle">{{ date.title }}</span>
            <!-- 2021年中国国际酒店投资加盟及特许经营... -->
          <!-- <span>
             {{ $t("language.Organizer") }}
          </span> -->
          <!-- 主办单位:烟台农业文化传播有限公司 -->
        </div>
      </div>
    <ul class="h5list">
      <li
              v-for="(item, index) in newslist"
              :key="index"
              @click="lookparticulars(item.id)"
            >
              <span
                >.
                {{ item.title }} </span
              ><span style="color: #666">{{ item.date }}</span>
            </li>
    </ul>
    <div class="h5visitor">
      <img :src="dataa.introduceImg" alt="">
      <h4>
         {{ dataa.title }}
        <!-- 2021年中国国际酒店投资加盟及特许... -->
      </h4>
      <p  v-html="dataa.introduce">
        <!-- 由山东烟台国际会展有限公司主办的2021中国国际酒店投资加盟与特许经营展览会于26日上午9:00 -->
      </p>
      <p><span>{{ dataa.date }}</span><span  @click="lookparticulars(dataa.id)">浏览更多</span></p>
    </div>
    <div class="h5visitor">
      <img :src="datab.introduceImg" alt="">
      <h4>
          {{ datab.title }}
        <!-- 2021年中国国际酒店投资加盟及特许... -->
        </h4>
      <p  v-html="datab.introduce">
        <!-- 由山东烟台国际会展有限公司主办的2021中国国际酒店投资加盟与特许经营展览会于26日上午9:00 -->
      </p>
      <p style="padding-bottom: 0.2rem; "><span>{{ datab.date }}</span><span  @click="lookparticulars(datab.id)">
         {{ $t("language.BrowseMore") }}
        <!-- 浏览更多 -->
        </span></p>
    </div>
    </div>
    <H5Bottom/>
    <div class="noticeNotice">
            <img ref="contentIcon" class="contentIcon" src="../../assets/image/编组.png" alt="" @click="gonoticeNotice"/>
            <p class="icon" ref="icon" @click="noticeNoticeOpen">
              <img src="../../assets/image/折叠.png" alt=""/>
            </p>
          </div>
  </div>
</template>

<script>
import H5Carousel from '../../components/h5-carousel.vue'
import H5Bottom from '../../components/h5bottom.vue'
export default {
    data() {
        return {
           newslist: [],
           date: [],
           dataa: [],
           datab: [],
           aaa:false,
           bbb:false,
           ccc:false,
        }
    },
    components:{
        H5Carousel,H5Bottom
    },
    created() {
    this.getlist();
    if (typeof window !== 'undefined') {
      document.addEventListener('click', (e) => {
        if (e.target.className !== 'shows') {
          this.aaa = false
          this.bbb = false
          this.ccc = false
        }
      })
    }
    },
    methods: {
      noticeNoticeOpen(){
        if (this.$refs.contentIcon.style.width == "0rem") {
          this.$refs.contentIcon.style.width = "1rem"
          this.$refs.icon.style.width = "1rem"
        }else{
          this.$refs.contentIcon.style.width = "0rem"
          this.$refs.icon.style.width = "0.3rem"
        }
      },
      gonoticeNotice(){
        this.$router.replace({
          path: "/TradeShowTrends",
          query: { path: `通知公告` },
        });
      },
      getlist() {
      let data = {
        pageNum: 1,
        pageSize: 10,
        newsType:'1'
      };
      this.$api.newsandtrends(data, (res) => {
        if (res.data.code) {
          let newslist = res.data.rows.map((item) => {
            item.date = item.releaseTime.substring(0, 11);
            item.newsContent = `${item.newsContent.substring(0, 100)}...`;
            return item;
          });
          this.dataa = newslist.splice(0, 1)[0];
           this.datab = newslist.splice(0, 1)[0];
          this.date = newslist.splice(0, 1)[0];
          this.newslist = newslist.splice(0, 5);
        }
      });
    },
    goNews() {
      this.$router.replace({
        path: "/TradeShowTrends",
        query: { path: `新闻动态` },
      });
    },
    lookparticulars(id) {
      this.$router.push({ path: "lookparticulars", query: { id: id } });
    },
    gopage(title,val){
        this.$router.push({path:val,query:{path:title}})
      },
    Mask1(){
      this.aaa =! this.aaa;
      this.bbb = false
      this.ccc = false
    },
    Mask2(){
      this.bbb =! this.bbb;
      this.aaa = false
      this.ccc = false
    },
    Mask3(){
      this.ccc =! this.ccc;
      this.aaa = false
      this.bbb = false
    }
    },
}
</script>

<style scoped>
  .shows{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;top: 0;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    z-index: 2;
    padding: 0.3rem 0.4rem;
    box-sizing: border-box;
    transition: all 2s;
  }
  .shows{
    display: flex;
    flex-wrap: wrap;
  }
  .shows p{
    width: 0.85rem;
    text-align: center;
    height: 25%;
    margin-top: 0;
  }
  .show{
    display: flex;
    flex-wrap: wrap;
  }
  .show p{
    width: 0.85rem;
    text-align: center;
    height: 25%;
    margin-top: 0;
  }
  .shows p:nth-of-type(1){
    width: 0.75rem;
  }
  .centcon{
    font-size: 0.14rem;
    color: #fff;
  }
  .centers{
    font-size: 0.3rem;
    color: #fff;
    margin-bottom: 0.08rem;
  }
    .h5box{
    width: 100%;
    height: 100%;
  }
  .h5left{
    width: 90%;
    margin: 0.1rem auto;
    background-image: url('../../assets/image/lALPDiCpv39EzgTM8M0Bvg_446_240.png');
    background-size: 100% 100%;
    height: 1.8rem;
    padding: 0.57rem 0 0 0.2rem;
    box-sizing: border-box;
    position: relative;
  }
  .h5center{
    width: 90%;
    margin: 0.1rem auto;
    background-image: url('../../assets/image/lALPDhmOwjW0QSjM8M0Bvg_446_240.png');
    background-size: 100% 100%;
    height: 1.8rem;
    padding: 0.57rem 0 0 0.2rem;
    box-sizing: border-box;
    position: relative;
  }
  .h5right{
    width: 90%;
    margin: 0.1rem auto;
    background-image: url('../../assets/image/lALPDhmOwjW3zgPM8M0Bvg_446_240.png');
    background-size: 100% 100%;
    height: 1.8rem;
    padding: 0.57rem 0 0 0.2rem;
    box-sizing: border-box;
    position: relative;
  }
  .H5nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 0.1rem) ;
    height: 0.4rem;
    margin: 0 auto;
    box-sizing: border-box;
    border-bottom: 0.01rem solid #d9d9d9;
  }
  .H5nav span:nth-of-type(1){
    font-size: 0.14rem;
    color: #12288b;
    height: 100%;
    line-height: 0.4rem;
    border-bottom: 0.01rem solid #12288b;
    display: block;
  }
  .H5nav span:nth-of-type(2){
    height: 100%;
    line-height: 0.4rem;
    font-size: 0.12rem;
    display: block;
  }
  .journalism{
    width: 100%;
    height: auto;
    background: #ecf0f4;
  }
  .h5explain{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 0.64rem;
    padding: 0 0.1rem;
    box-sizing: border-box;
    margin-top: 0.15rem;
  }
  .h5explainleft{
    width: 0.7rem;
    height: 0.6rem;
    color: #fff;
    background: #12288b;
    text-align: center;
    line-height: 0.32rem;
  }
  .h5explainleft p:nth-of-type(1){
    font-size: 0.24rem;
  }
  .h5explainleft p:nth-of-type(2){
    border-bottom: 0.01rem solid #fff;
    width: 0.2rem;
    margin: 0 auto;
  }
  .h5explainleft p:nth-of-type(2){
    font-size: 0.14rem;
  }
  .h5explainright{
    width: 2.8rem;
    height: 100%;
    line-height: 0.2rem;
  }
  .h5explainright span:nth-of-type(1){
    width: 100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    display: block;
    color: #12288b;
    font-size: 0.14rem;
  }
  .h5list{
    width: 100%;
    height: auto;
  }
  .h5list li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    line-height: 0.24rem;
    padding: 0 0.1rem;
    box-sizing: border-box;
  }
  .h5list li span:nth-of-type(1){
    width: 2.8rem;
    display: inline-block;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
  .h5visitor{
    width: 100%;
    height: auto;
    padding: 0 0.1rem;
    box-sizing: border-box;
    margin-top: 0.2rem;
  }
  .h5visitor img{
    width: 100%;
    height: auto;
  }
  .h5visitor h4{
    font-size: 0.15rem;
    width: 80%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    margin: 0 auto;
  }
  .h5visitor p:nth-of-type(1){
    width: 95%;
    margin: 0.1rem auto;
  }
  .h5visitor p:nth-of-type(2){
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }
  .lyric-enter,.lyric-leave-to{
            opacity:  0;/*透明度*/
        }
.lyric-enter-active,
.lyric-leave-active {
  transition: all 0.5s;
}
.noticeNotice{
  width: 1rem;
  height: 1.25rem;
  position: fixed;
  top: 3rem;
  right: 0;
}
.contentIcon{
    position: absolute;
    right: 0;
    top: 0;
    width: 1rem;
    height: 1rem;
    transition: .3s;
  }
  .icon{
    position: absolute;
    right: 0;
    bottom: 0;
    background: #409EFF;
    width: 1rem;
    height: 0.2rem;
    text-align: center;
    cursor: pointer;
    transition: .3s;
  }
</style>
